<template>
    <div class="container content">
        <div class="mb-4">
            <router-link :to="{ name: 'cart' }" class="btn btn-secondary btn-sm">Back to Cart</router-link>
        </div>

        <div class="alert alert-secondary login-reminder" v-if="!currentUser">
            <p class="mb-0">Already have an account? <a href="#" @click.prevent="$emit('login')">Log in</a> for a faster checkout.</p>
        </div>

        <h1 class="h1 mb-3">Checkout</h1>

        <form  action="#" method="post" class="form-checkout" @submit.prevent="handlePreSubmit">
            <div class="mb-4 gift-order">
                <label for="allow_substitutions" class="d-inline-flex align-items-center mb-0">
                    <input type="checkbox" id="allow_substitutions" v-model="allow_substitutions" class="large" />
                    <span class="px-1">
                        <img src="/images/icons/substitute.svg" width="17px" height="17px" style="margin-top: -3px; margin-left: 3px;" />
                    </span>
                    Allow Substitutions
                </label>
                <div>If an item is unavailable when preparing your order, allow us to substitute some or all of it for a similar item.</div>
            </div>

            <div class="mb-2 gift-order">
                <label for="gift" class="d-inline-flex align-items-center mb-0">
                    <input type="checkbox" id="gift" v-on:click="toggle_gift()" v-model="gift" class="large" />
                    <span class="px-1">
                        <img src="/images/icons/gift.svg" width="18px" height="18px" style="margin-top: -5px; margin-left: 2px;" />
                    </span>
                    This order is a gift
                </label>
            </div>

            <div v-if="gift" class="row mb-2">
                <div class="col-md-6">
                    <div class="form-group row mx-0">
                        <label class="col-3 px-1 m-0" style="align-self: center">Gift For:</label>
                        <input type="text" maxlength="100" name="gift_to" id="gift_to" class="form-control col-9" v-model.trim="gift_to" :required="gift" placeholder="Recipient (required)"/>
                    </div>
                    <textarea v-model.trim="gift_message" type="text" maxlength="250" rows="4" style="resize:none" name="gift_message" id="gift_message" class="form-control" placeholder="Enter a gift message (optional)" />
                </div>
            </div>

            <transition name="fade">
                <div class="">
                    <h3>Shipping Information</h3>

                    <div v-show="showShippingAddressForm">
                        <div class="row">
                            <div class="col-md mb-1">
                                <input
                                    v-model.trim="shipping.first_name"
                                    type="text"
                                    name="delivery_first_name"
                                    id="delivery_first_name"
                                    class="form-control"
                                    placeholder="First Name"
                                    @blur="touched.shipping.first_name = !shipping.first_name"
                                    :class="{ invalid: !checkIsValid('first_name') }"
                                    required
                                />
                            </div>
                            <div class="col-md mb-1">
                                <input
                                    v-model.trim="shipping.last_name"
                                    type="text"
                                    name="delivery_last_name"
                                    id="delivery_last_name"
                                    class="form-control"
                                    placeholder="Last Name"
                                    @blur="touched.shipping.last_name = !shipping.last_name"
                                    :class="{ invalid: !checkIsValid('last_name') }"
                                    required
                                />
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-md mb-1">
                                <input
                                    v-model.trim="shipping.phone"
                                    type="text"
                                    name="delivery_phone"
                                    id="delivery_phone"
                                    class="form-control"
                                    placeholder="Phone"
                                    @blur="touched.shipping.phone = !shipping.phone"
                                    :class="{ invalid: !checkIsValid('phone') }"
                                    required
                                />
                            </div>
                            <div class="col-md mb-1">
                                <input
                                    v-model.trim="shipping.email"
                                    type="email"
                                    name="delivery_email"
                                    id="delivery_email"
                                    class="form-control"
                                    placeholder="Email"
                                    @blur="blurEmail"
                                    :class="{ invalid: !checkIsValid('email') }"
                                    required
                                />
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-md mb-1">
                                <input
                                    v-model.trim="shipping.full_address"
                                    type="text"
                                    name="delivery_address"
                                    id="delivery_address"
                                    class="form-control"
                                    placeholder="Address"
                                    required
                                    autocomplete="off"
                                    @change="clearShippingAddress()"
                                    @blur="checkShippingAddress()"
                                    :class="{
                                        invalid: touched.shipping.full_address && (!shipping.lat || !shipping.lng)
                                    }"
                                    ref="autocomplete_shipping"
                                />
                            </div>
                            <div class="col-md mb-1">
                                <input
                                    v-model.trim="shipping.address_2"
                                    type="text"
                                    name="delivery_address_2"
                                    id="delivery_address_2"
                                    class="form-control"
                                    placeholder="Apt, Suite, Etc."
                                />
                            </div>
                        </div>

                        <div class="row" v-if="touched.autocomplete && showAdditionalInputs">
                            <div class="col-md-6 mb-1" v-if="!googleCity">
                                <input
                                    v-model.trim.lazy="shipping.city"
                                    type="text"
                                    name="delivery_city"
                                    id="delivery_city"
                                    class="form-control"
                                    placeholder="City"
                                    :class="{ invalid: !shipping.city }"
                                    @blur="checkShippingAddress()"
                                    required
                                />
                            </div>
                            <div class="col-md-6 mb-1" v-if="!googlePostalCode">
                                <input
                                    v-model.trim="shipping.postal"
                                    type="text"
                                    name="delivery_postal"
                                    id="delivery_postal"
                                    class="form-control"
                                    placeholder="Postal"
                                    required
                                    maxlength="7"
                                    @keyup="replaceOWithZero"
                                    @blur="checkShippingAddress()"
                                    :class="{ invalid: isShippingPostalInvalid }"
                                >
                            </div>
                        </div>
                    </div>

                    <div v-if="!showShippingAddressForm" class="d-flex justify-items-between align-items-start">
                        <p>
                            {{ shipping.first_name }} {{ shipping.last_name }} <br>
                            {{ shipping.address_1 }} {{ shipping.address_2 }} <br>
                            {{ shipping.city }} {{ shipping.province }} {{ shipping.postal }} <br>
                            {{ shipping.email }} <br>
                            {{ shipping.phone }}
                        </p>

                        <button
                            type="button"
                            class="btn btn-sm btn-outline-secondary ml-5"
                            @click="showShippingAddressForm = !showShippingAddressForm"
                        >Edit</button>
                    </div>

                    <div v-if="!currentUser">
                        <label class="d-inline-flex align-items-center mb-0" style="line-height: 1.2;">
                            <input type="checkbox" name="gift" v-model="create_account" />
                            <span class="px-1">Create an account using this information</span>
                        </label>

                        <div v-if="create_account" class="row">
                            <div class="col-md-6">
                                <input v-model.trim="password" type="password" name="password" class="form-control mb-1" placeholder="Password" required />
                                <span v-if="form_errors.password" class="d-inline-block text-danger mb-2">{{ form_errors.password[0] }}</span>
                                <input v-model.trim="password_confirmation" type="password" name="password_confirmation" class="form-control mb-1" placeholder="Confirm Password" required />
                                <span v-if="form_errors.password_confirmation" class="d-inline-block text-danger mb-2">{{ form_errors.password_confirmation[0] }}</span>
                            </div>
                        </div>
                    </div>

                    <p class="my-3 text-danger" v-if="needConfirmAddress || (isTouched('shipping') && !isShippingAddressComplete)">
                        {{ needConfirmAddress ? 'Please confirm your address!' : 'Please complete shipping information: name, phone number, email, address.' }}
                    </p>

                    <shipping
                        :shipping="shipping"
                        :available-produce-express-dates="availableProduceExpressDates"
                        :available-dates="pickup_dates"
                        :shipping-trigger="shippingTrigger"
                        :is-shipping-address-complete="isShippingAddressComplete"
                        :is-shipping-postal-invalid="isShippingPostalInvalid"
                        :need-confirm-address="needConfirmAddress"
                    />

                    <div v-bind:class="{ faded: !isShippingMethodComplete || !isShippingAddressComplete }">
                        <h3 class="mt-3">Payment Information</h3>

                        <div style="font-size: 17px" class="mb-2">
                            <div v-if="isDelivery && delivery && delivery > 0">
                                <h5>Shipping: <span>${{ delivery.toFixed(2) }}</span></h5>
                            </div>

                            <div v-if="tax > 0">
                                <h5>Tax: <span>${{ tax.toFixed(2) }}</span></h5>
                            </div>

                            <h5 class="mb-3">
                                Order Total:
                                <span v-show="total != grandTotal">
                                    <s>${{ total.toFixed(2) }}</s>
                                </span>
                                ${{ grandTotal.toFixed(2) }}
                            </h5>
                        </div>


                        <div class="row mb-2">
                            <div class="col-md-6">
                                <label for="promo_code" class="mb-0">Promo Code / Gift Card Code</label>

                                <div class="input-group">
                                    <input id="promo_code" type="text" class="form-control" v-model="promo_code" v-on:input="promo_code_error = ''" placeholder="Code" :disabled="hasPromo || !isShippingMethodComplete || !isShippingAddressComplete">
                                    <div class="input-group-append">
                                        <button type="button" class="btn btn-outline-secondary" @click="applyPromo" v-if="!hasPromo" :disabled="!isShippingMethodComplete">Apply</button>
                                        <button type="button" class="btn btn-outline-secondary" @click="removePromo" v-else :disabled="!isShippingMethodComplete">X</button>
                                    </div>
                                </div>

                                <div id="promo_code_applied" class="text_center_if_small small text-success pt-2" v-show="hasPromo">
                                    Promo Code / Gift Card Code Applied!
                                </div>
                                <div id="promo_code_errors" class="text_center_if_small small text-danger pt-2" v-text="promo_code_error"></div>
                            </div>
                        </div>

                        <div class="row mb-2">
                            <div class="col-md-6">
                                <label for="fundraising_code" class="mb-0">Fundraising Code</label>

                                <div class="input-group">
                                    <input id="fundraising_code" type="text" class="form-control" v-model="fundraising_code" v-on:input="fundraising_code_error = ''" placeholder="Code" :disabled="applied_fundraising_code != '' || !isShippingMethodComplete || !isShippingAddressComplete">
                                    <div class="input-group-append">
                                        <button type="button" class="btn btn-outline-secondary" v-on:click="applyFundraising()" v-show="applied_fundraising_code == ''" :disabled="!isShippingMethodComplete">Apply</button>
                                    </div>
                                </div>

                                <div id="fundraising_code_applied" class="text_center_if_small small text-success pt-2" v-show="applied_fundraising_code != ''">
                                    Fundraising Code Applied!
                                </div>
                                <div id="fundraising_code_errors" class="text_center_if_small small text-danger pt-2" v-text="fundraising_code_error"></div>
                            </div>
                        </div>

                        <div v-if="requiresPayment">
                            <label for="credit_card" class="mb-0">Credit Card</label>

                            <payment
                                :errorMessage="card_error"
                                :isShippingMethodComplete="isShippingMethodComplete"
                                :isShippingAddressComplete="isShippingAddressComplete"
                                :validate="requiresPayment"
                                :is-order-complete="is_order_complete"
                                @credit-card-update="updateCardFields"
                                @vault-token-update="updateCardFields"
                            />
                        </div>

                        <h3>Billing Information</h3>

                        <div>
                            <input
                                v-model="billing_same_as_shipping"
                                type="checkbox"
                                name="billing_same_as_shipping"
                                id="billing_same_as_shipping"
                                :disabled="!isShippingMethodComplete"
                            />
                            <label class="px-1" for="billing_same_as_shipping">Same as shipping address</label>
                        </div>

                        <div class="delivery mb-2" v-show="!billing_same_as_shipping">
                            <div v-if="showBillingAddressForm">
                                <div class="row">
                                    <div class="col-md mb-1">
                                        <input
                                            v-model.trim="first_name"
                                            type="text"
                                            name="first_name"
                                            id="first_name"
                                            class="form-control"
                                            placeholder="First Name"
                                            :required="!billing_same_as_shipping"
                                            :disabled="!isShippingMethodComplete"
                                            @blur="touched.billing.first_name = !first_name"
                                            :class="{ invalid: !checkIsValid('first_name', false) }"
                                        />
                                    </div>
                                    <div class="col-md mb-1">
                                        <input
                                            v-model.trim="last_name"
                                            type="text"
                                            name="last_name"
                                            id="last_name"
                                            class="form-control"
                                            placeholder="Last Name"
                                            :required="!billing_same_as_shipping"
                                            :disabled="!isShippingMethodComplete"
                                            @blur="touched.billing.last_name = !last_name"
                                            :class="{ invalid: !checkIsValid('last_name', false) }"
                                        />
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-md mb-1">
                                        <input
                                            v-model.trim.lazy="address_1"
                                            type="text"
                                            name="address_1"
                                            id="address_1"
                                            class="form-control"
                                            placeholder="Address"
                                            :required="!billing_same_as_shipping"
                                            :disabled="!isShippingMethodComplete"
                                            @blur="touched.billing.address_1 = !address_1"
                                            :class="{ invalid: !checkIsValid('address_1', false) }"
                                        />
                                    </div>
                                    <div class="col-md mb-1">
                                        <input
                                            v-model.trim="address_2"
                                            type="text"
                                            name="address_2"
                                            id="address_2"
                                            class="form-control"
                                            placeholder="Apt, Suite, Etc."
                                            :disabled="!isShippingMethodComplete"
                                        />
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-md mb-1">
                                        <input
                                            v-model.trim.lazy="city"
                                            type="text"
                                            name="city"
                                            id="city"
                                            class="form-control"
                                            placeholder="City"
                                            :required="!billing_same_as_shipping"
                                            :disabled="!isShippingMethodComplete"
                                            @blur="touched.billing.city = !city"
                                            :class="{ invalid: !checkIsValid('city', false) }"
                                        />
                                    </div>
                                    <div class="col-md mb-1">
                                        <input
                                            v-model.trim.lazy="province"
                                            type="text"
                                            name="province"
                                            id="province"
                                            class="form-control"
                                            placeholder="Province"
                                            :required="!billing_same_as_shipping"
                                            :disabled="!isShippingMethodComplete"
                                            @blur="touched.billing.province = !province"
                                            :class="{ invalid: !checkIsValid('province', false) }"
                                        />
                                    </div>
                                    <div class="col-md mb-1">
                                        <input
                                            v-model.trim="postal"
                                            type="text"
                                            name="postal"
                                            id="postal"
                                            class="form-control"
                                            placeholder="Postal"
                                            maxlength="7"
                                            :required="!billing_same_as_shipping"
                                            @blur="touched.billing.postal = isBillingPostalInvalid"
                                            :class="{ invalid: touched.billing.postal && isBillingPostalInvalid }"
                                            :disabled="!isShippingMethodComplete"
                                            @keyup="replaceOWithZero"
                                        >
                                    </div>
                                </div>

                                 <div class="row">
                                    <div class="col-md-6 mb-1">
                                        <input
                                            v-model.trim="phone"
                                            type="text"
                                            name="phone"
                                            id="phone"
                                            class="form-control"
                                            placeholder="Phone"
                                            :required="!billing_same_as_shipping"
                                            :disabled="!isShippingMethodComplete"
                                            @blur="touched.billing.phone = !phone"
                                            :class="{ invalid: !checkIsValid('phone', false) }"
                                        />
                                    </div>
                                </div>
                            </div>

                            <div v-else class="d-flex justify-items-between align-items-start">
                                <p>
                                    {{ first_name }} {{ last_name }} <br>
                                    {{ address_1 }} {{ address_2 }} <br>
                                    {{ city }} {{ province }} {{ postal }} <br>
                                    {{ phone }}
                                </p>

                                <button
                                    type="button"
                                    class="btn btn-sm btn-outline-secondary ml-5"
                                    @click="showBillingAddressForm = !showBillingAddressForm"
                                >Edit</button>
                            </div>

                            <p class="my-3 text-danger" v-if="(isTouched('billing') && !isBillingAddressComplete)">
                                {{ 'Please complete billing information: name, phone number, postal code, address.' }}
                            </p>
                        </div>

                        <div>
                            <input
                                v-model="optin_promotional_emails"
                                type="checkbox"
                                name="optin_promotional_emails"
                                id="optin_promotional_emails"
                                :disabled="!isShippingMethodComplete"
                            />
                            <label class="px-1" for="optin_promotional_emails">Yes! I would like to receive promotional emails.</label>
                        </div>

                        <div class="text-center">
                            <p v-show="showShippingError" class="text-danger mt-4" style="line-height: 1.2">Please select a {{ shipping.method }} date before continuing</p>
                            <p v-show="is_order_processing" style="font-weight: bold;" class="mt-5">Processing your order, please wait ...</p>
                            <input
                                type="submit"
                                value="Submit Order"
                                class="btn btn-lg btn-primary"
                                :class="{ 'mt-5': !showShippingError }"
                                :disabled="isSubmitDisabled || !isShippingMethodComplete"
                                v-show="!is_order_processing"
                            >
                        </div>
                    </div>
                </div>
            </transition>
        </form>

        <modal v-if="show_modal" class="pricing outlined">
            <template #header>
                <h3>Cart Update</h3>
            </template>

            <div slot="body">
                <p>The following product(s) have been updated since you started shopping:</p>

                <ol>
                    <li v-for="(change, index) in product_changes" v-html="change" :key="index"></li>
                </ol>

                <p>Your cart has been updated to reflect these changes.</p>
                <p>Please review your order and submit again.</p>
            </div>
            <div slot="footer">
                <button class="btn btn-secondary" @click="handleNavigateToCart">
                    View Cart
                </button>
            </div>
        </modal>

        <modal v-if="bad_field_modal" @close="bad_field_modal = false" :closeable="true">
            <div slot="header"><h3 class="mt-0">Missing Information</h3></div>
            <div slot="body">
                <ul>
                    <li v-for="(bad_field, index) in bad_fields" v-html="bad_field" :key="index"></li>
                </ul>

                <p>Please try again after correcting these fields.</p>
            </div>
            <div slot="footer">
                <button class="btn btn-secondary" @click="bad_field_modal = false">
                    Ok
                </button>
            </div>
        </modal>

        <modal v-if="problem_modal">
            <div slot="header"><h3 class="mt-0">Error placing order</h3></div>
            <div slot="body">
                <p>Sorry, we ran into a problem placing your order.</p>
                <p>Can you please refresh and try again?</p>
            </div>
            <div slot="footer">
                <button class="btn btn-secondary" onclick="location.reload()">
                    Refresh
                </button>
            </div>
        </modal>

        <modal v-if="popup_modal" @close="popup_modal = false">
            <div slot="header"><p>Please Review</p></div>
            <div slot="body" v-if="shipping.method == 'pickup'">
                <p>
                    You are picking this order up at:
                    <table class="m-2" style="display:block;">
                        <tr>
                            <td style="padding-right:1rem">Location:</td>
                            <td style="font-weight: bold"> {{shipping.pickupLocation.name}}</td>
                        </tr>
                        <tr>
                            <td>Date:</td>
                            <td style="font-weight: bold">  {{ formatDate(shipping.date) }}</td>
                        </tr>
                        <tr>
                            <td>Time slot:</td>
                            <td style="font-weight: bold">  {{shipping.time.label}}</td>
                        </tr>
                    </table>
                    If this is not correct, please press cancel and edit your selection.
                </p>
                <p>
                    Once submitted, you cannot add or remove items from your order or change the pick-up date or time.
                </p>
            </div>
            <div slot="body" v-if="shipping.method == 'delivery'">
                <p>
                    You are getting this order delivered to:
                    <span class="m-2" style="display:block; font-weight:bold">
                        {{ shipping.address_1 }} <br>
                        {{ shipping.city }} {{ shipping.province }}, {{ shipping.postal }}
                    </span>
                    If this address is correct, please press continue. Otherwise, please press cancel and edit your address.
                </p>
                <p>
                    Once submitted, you cannot add or remove items from your order or change the delivery address or day.
                </p>
            </div>
            <div slot="footer">
                <button class="btn btn-secondary mr-2" @click="handleSubmit">
                    Continue
                </button>

                <button class="btn btn-secondary" @click="popup_modal = false">
                    Cancel
                </button>
            </div>
        </modal>

        <modal v-if="bad_slot_modal" @close="bad_slot_modal = false" :closeable="true">
            <div slot="header"><h3 class="mt-0">Pickup Unavailable</h3></div>
            <div slot="body">
                <p>Sorry, your chosen date/time are no longer available. Please review the available options and make another selection.</p>
            </div>
            <div slot="footer">
                <button class="btn btn-secondary" @click="bad_slot_modal = false">
                    Ok
                </button>
            </div>
        </modal>

        <modal v-if="bad_delivery_slot_modal" @close="bad_delivery_slot_modal = false" :closeable="true">
            <div slot="header"><h3 class="mt-0">Delivery Unavailable</h3></div>
            <div slot="body">
                <p>Sorry, your chosen date/time are no longer available. Please review the available options and make another selection.</p>
            </div>
            <div slot="footer">
                <button class="btn btn-secondary" @click="bad_delivery_slot_modal = false">
                    Ok
                </button>
            </div>
        </modal>

        <modal v-if="bad_address_modal" @close="bad_address_modal = false" :closeable="true">
            <div slot="header"><h3 class="mt-0">Delivery Unavailable</h3></div>
            <div slot="body">
                <p>Sorry, delivery is no longer available in your area. Please check back later.</p>
            </div>
            <div slot="footer">
                <button class="btn btn-secondary" @click="bad_address_modal = false">
                    Ok
                </button>
            </div>
        </modal>

        <modal v-if="bad_cart_modal">
            <div slot="header"><h3 class="mt-0">Product(s) Unavailable</h3></div>
            <div slot="body">
                <p>One or more products are not available for your pickup/delivery location and have been removed from your cart.</p>
                <p>Please review your order and submit again.</p>
            </div>
            <div slot="footer">
                <button class="btn btn-secondary" @click="handleNavigateToCart">
                    View Cart
                </button>
            </div>
        </modal>

        <modal v-if="bad_basket_modal" @close="bad_basket_modal = false" class="cart-updates">
            <div slot="header">
                <h3 class="mt-0">Cart Update</h3>
            </div>
            <div slot="body">
                <p>Gift basket items have been updated since you started shopping.</p>
                <p>Please review your order and try again.</p>
            </div>
            <div slot="footer">
                <button class="btn btn-secondary" @click="handleNavigateToCart">
                    View Cart
                </button>
            </div>
        </modal>

        <modal v-if="adjust_for_combos_modal" class="pricing outlined">
            <template #header>
                <h3>Cart Update</h3>
            </template>

            <div slot="body">
                <p>The following combo(s) have been updated to reflect availability:</p>

                <ol>
                    <li v-for="(change, index) in product_combo_changes" v-html="change" :key="index"></li>
                </ol>

                <p>Your cart has been updated to reflect these changes.</p>
                <p>Please review your order and submit again.</p>
            </div>
            <div slot="footer">
                <button class="btn btn-secondary" @click="handleNavigateToCart">
                    View Cart
                </button>
            </div>
        </modal>

        <modal v-if="show_cart_has_updated_modal">
            <div slot="header">
                <h3 class="mt-0">Cart Update</h3>
            </div>
            <div slot="body">
                <p>Your cart has been updated with items previously saved to your account.</p>

                <p>Please review before continuing.</p>
            </div>
            <div slot="footer">
                <button class="btn btn-primary" @click="handleNavigateToCart">
                    View Cart
                </button>
            </div>
        </modal>

        <modal v-if="show_invalid_location_modal" @close="handleInvalidModalClose">
            <div slot="header">
                <h3>
                    Product<span v-show="invalid_location_products.length > 1">s</span>
                    Unavailable
                </h3>
            </div>
            <div slot="body">
                <p>The following items are not available at the selected location and have been removed from your cart.</p>

                <ul>
                    <li v-for="product in invalid_location_products" :key="product.id">
                        {{ product.title }} - ${{ product.price }} / {{ product.size }}
                    </li>
                </ul>
            </div>
            <div slot="footer">
                <button class="btn btn-secondary" @click="handleInvalidModalClose">
                    Ok
                </button>
            </div>
        </modal>
    </div>
</template>

<script>
    import { mapGetters, mapState } from 'vuex'
    import Shipping from '@/components/Shipping.vue'
    import Payment from '@/components/Payment.vue'
    import Modal from '@/components/Modal.vue'
    import { trackBeginCheckout, trackPurchase } from '../dataLayer.js';

    const validPostalCode = /[ABCEGHJKLMNPRSTVXY][0-9][ABCEGHJKLMNPRSTVWXYZ] ?[0-9][ABCEGHJKLMNPRSTVWXYZ][0-9]/i;

    let initialData = {
        first_name: '',
        last_name: '',
        phone: '',
        address_1: '',
        address_2: '',
        city: '',
        province: '',
        postal: '',

        touched: {
            autocomplete: false,
            shipping: {
                first_name: false,
                last_name: false,
                phone: false,
                email: false,
                full_address: false,
                city: false,
                postal: false,
            },
            billing: {
                first_name: false,
                last_name: false,
                phone: false,
                city: false,
                address_1: false,
                province: false,
                postal: false,
            },
        },

        billing_same_as_shipping: true,
        needConfirmAddress: false,
        showAdditionalInputs: false,
        googleCity: '',
        googlePostalCode: '',

        shipping: {
            oldEmail: '',
            method: '',
            date: '',
            dateLabel: '',
            time: '',
            first_name: '',
            last_name: '',
            phone: '',
            email: '',
            address_1: '',
            address_2: '',
            city: '',
            postal: '',
            province: '',
            lat: '',
            lng: '',
            notes: '',
            deliveryZone: '',
            pickupLocation: '',
            full_address: '',
        },

        create_account: false,
        password: '',
        password_confirmation: '',

        cardnumber: '',
        expiry: '',
        cvd: '',
        card_error: '',
        vault_id: null,
        save_to_vault: false,

        is_order_complete: false,
        is_order_processing: false,

        pickup_dates: [],
        payment_status: '',
        product_changes: [],
        show_modal: false,
        show_cart_has_updated_modal: false,
        show_invalid_location_modal: false,
        invalid_location_products: [],

        bad_field_modal: false,
        bad_fields: [],
        problem_modal: false,
        popup_modal: false,
        bad_slot_modal: false,
        bad_delivery_slot_modal: false,
        bad_address_modal: false,
        bad_cart_modal: false,
        bad_basket_modal: false,
        product_combo_changes: [],
        adjust_for_combos_modal: false,

        fundraising_code: '',
        fundraising_code_error: '',
        applied_fundraising_code: '',

        promo_code: '',
        promo_code_error: '',
        promo_usage: '',

        allow_substitutions: false,

        gift: false,
        gift_to: '',
        gift_message: '',

        searchInput: null,

        showShippingError: false,
        showShippingAddressForm : true,
        showBillingAddressForm : true,

        availableProduceExpressDates: [],
        shippingTrigger: '',

        optin_promotional_emails: false,
    }

    export default {
        name: 'Checkout',

        components: {
            Shipping,
            Payment,
            Modal,
        },

        metaInfo () {
            return {
                title: 'Checkout',
                meta: [
                    { charset: 'utf-8' },
                    { vmid: 'robots', name: 'robots', content: 'noindex' }
                ],
            }
        },

        data () {
            return {
                ...initialData,

                form_errors: [],

                originalGuestCart: [],
                autocompleteShippingAddress: null,
            }
        },

        computed: {
            ...mapGetters([
                'subtotal',
                'tax',
                'total',
                'discount',
                'grandTotal',
                'productsInCart',
                'promo',
                'delivery'
            ]),

            ...mapState({
                currentUser: state => state.user.currentUser,
            }),

            isDelivery() {
                return this.shipping.method === 'delivery';
            },

            requiresPayment () {
                return this.grandTotal > 0;
            },

            isShippingMethodComplete() {
                if (this.shipping.method === 'pickup' && this.shipping.pickupLocation.id === 0 && this.shipping.date && this.shipping.time) {
                    return true;
                }
                else if (this.shipping.method === 'pickup' && this.shipping.pickupLocation.id !== 0 && this.shipping.date) {
                    return true;
                }
                else if (this.shipping.method === 'delivery' && this.shipping.date && this.shipping.time) {
                    return true;
                }

                return false;
            },

            isShippingAddressComplete() {
                return Boolean(this.shipping.first_name &&
                    this.shipping.last_name &&
                    this.shipping.phone &&
                    this.checkEmail(this.shipping.email) &&
                    this.shipping.address_1 &&
                    this.shipping.city &&
                    this.shipping.province &&
                    !this.isShippingPostalInvalid);
            },

            isBillingAddressComplete () {
                if (this.billing_same_as_shipping) {
                    return this.isShippingAddressComplete;
                }

                return Boolean(this.first_name &&
                    this.last_name &&
                    this.phone &&
                    this.address_1 &&
                    this.city &&
                    this.province &&
                    !this.isBillingPostalInvalid);
            },

            isSubmitDisabled() {
                return Boolean(this.is_order_processing ||
                    new Date().getDay() === 0 ||
                    this.productsInCart.length === 0 ||
                    (this.grandTotal > 0 && this.cardnumber === '' && this.expiry === '' && this.cvd === '') ||
                    !this.isShippingMethodComplete ||
                    !this.isBillingAddressComplete ||
                    this.isBillingPostalInvalid ||
                    this.cartOnlyHasFreeItems);
            },

            isShippingPostalInvalid () {
                return !validPostalCode.test(this.shipping.postal.replace(' ', ''));
            },

            isBillingPostalInvalid () {
                if (this.billing_same_as_shipping) {
                    return this.isShippingPostalInvalid;
                }

                return !validPostalCode.test(this.postal.replace(' ', ''));
            },

            hasPromo () {
                return this.promo.code;
            },

            cartOnlyHasFreeItems () {
                return this.productsInCart.filter(p => p.price > 0).length === 0 ? true : false;
            },
        },

        methods: {
            checkIsValid(field, isShipping = true) {
                const method = isShipping ? 'shipping' : '';

                if (!method) {
                    if (this.touched['billing'][field] && !this[field]) {
                        return false;
                    }

                    return true;
                }

                if (this.touched[method][field] && field === 'full_address') {
                    return this.isShippingAddressComplete;
                }

                if (this.touched[method][field] && field === 'email') {
                    const isValid = this.checkEmail(this[method][field])

                    if (isValid) {
                        this.touched[method][field] = false;
                    }

                    return isValid;
                }

                if (this.touched[method][field] && !this[method][field]) {
                    return false;
                }

                return true;
            },

            checkEmail(email) {
                return String(email)
                    .toLowerCase()
                    .match(
                        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                    );
            },

            isTouched(method) {
                return Object.values(this.touched[method]).some(element => element === true);
            },

            blurEmail() {
                this.touched.shipping.email = true;

                if (
                    this.checkEmail(this.shipping.email) &&
                    this.isShippingMethodComplete &&
                    this.promo_usage === 2 &&
                    this.shipping.email !== this.shipping.oldEmail
                ) {
                    this.removePromo();
                }
            },

            replaceOWithZero() {
                this.shipping.postal = this.shipping.postal.replace('o', '0').replace('O', 0);
                this.postal = this.postal.replace('o', '0').replace('O', 0);
            },

            updateCardFields(data) {
                if (data.vault_id) {
                    this.vault_id = data.vault_id;
                    this.cardnumber = null;
                    this.expiry = null;
                    this.cvd = null;
                    this.save_to_vault = false;
                } else {
                    for (const [property, val] of Object.entries(data)) {
                        this[property] = val;
                    }

                    this.vault_id = null;
                }
            },

            applyPromo() {
                var promoCode = '';
                var promoCodeAmount = 0;
                var promoCodeType = '';

                if (this.shipping.email !== this.shipping.oldEmail) {
                    this.shipping.oldEmail = this.shipping.email;
                }

                const data = {
                    promo_code: this.promo_code,
                    shipping: this.shipping
                }

                this.axios
                    .post(process.env.VUE_APP_API_WHOLESALE + '/api/apply_promo_code_outlet', data)
                    .then(d => {
                        if (d.data.result == 'fail')
                        {
                            this.promo_code_error = d.data.message;
                            this.promo_usage = d.data.usage;
                            promoCode = '';
                            promoCodeAmount = 0;
                            promoCodeType = '';
                        }
                        else
                        {
                            this.promo_code_error = '';
                            this.promo_usage = d.data.usage;
                            promoCode = d.data.applied_promo_code.code;
                            promoCodeAmount = d.data.applied_promo_code.discount_amount;
                            promoCodeType = d.data.applied_promo_code.type;
                        }

                        this.$store.commit('setPromoCode', {
                            code: promoCode,
                            amount: promoCodeAmount,
                            type: promoCodeType,
                        });

                        if (!this.requiresPayment) {
                            this.vault_id = null;
                        }
                    })
                    .catch(function (error) {
                        alert(error);
                    });
            },

            removePromo () {
                this.promo_code = '';
                this.promo_usage = '';
                this.promo_code_error = '';

                this.$store.commit('resetPromo');
            },

            applyFundraising() {
                this.axios
                    .post(
                        process.env.VUE_APP_API_WHOLESALE + '/api/apply_fundraising_code_outlet',
                        { fundraising_code: this.fundraising_code }
                    )
                    .then(d => {
                        if (d.data.result == 'fail')
                        {
                            this.fundraising_code_error = d.data.message;
                            this.applied_fundraising_code = '';
                        }
                        else
                        {
                            this.fundraising_code_error = '';
                            this.applied_fundraising_code = d.data.applied_fundraising_code.code;
                        }
                    })
                    .catch(function (error) {
                        alert(error);
                    });
            },

            toggle_gift() {
                if(this.gift == true)
                {   //the box was just unchecked
                    this.gift_to = '';
                    this.gift_message = '';
                }
            },

            handlePreSubmit () {
                this.showShippingError = false;

                if (this.isShippingMethodComplete) {
                    this.popup_modal = true
                } else {
                    this.showShippingError = true;
                }
            },

            handleSubmit () {
                this.form_errors = [];
                this.popup_modal = false;
                this.card_error = '';
                this.shippingTrigger = '';

                if (this.is_order_processing) {
                    return;
                } else {
                    this.is_order_processing = true;
                }

                this.product_changes = [];
                this.product_combo_changes = [];

                var data = {
                    billing_first_name: this.first_name,
                    billing_last_name: this.last_name,
                    billing_phone: this.phone,
                    billing_address_1: this.address_1,
                    billing_address_2: this.address_2,
                    billing_city: this.city,
                    billing_province: this.province,
                    billing_postal: this.postal,
                    billing_same_as_shipping: this.billing_same_as_shipping,
                    shipping_first_name: this.shipping.first_name,
                    shipping_last_name: this.shipping.last_name,
                    shipping_phone: this.shipping.phone,
                    shipping_email: this.shipping.email,
                    shipping_address_1: this.shipping.address_1,
                    shipping_address_2: this.shipping.address_2,
                    shipping_city: this.shipping.city,
                    shipping_province: this.shipping.province,
                    shipping_postal: this.shipping.postal,
                    notes: this.shipping.notes,
                    shipping_zone: this.shipping.deliveryZone,
                    shipping_time: this.shipping.time.id,
                    ship_date: this.shipping.date,
                    shipping_method: this.shipping.method,
                    shipping_lat: this.shipping.lat,
                    shipping_lng: this.shipping.lng,
                    allow_substitutions: this.allow_substitutions,
                    gift: this.gift,
                    gift_to: this.gift_to,
                    gift_message: this.gift_message,
                    products: this.productsInCart,
                    order_total: this.grandTotal,
                    applied_fundraising_code: this.applied_fundraising_code,
                    applied_promo_code: this.promo.code,
                    pickup_location: this.shipping.pickupLocation,
                    cardnumber: this.cardnumber,
                    expiry: this.expiry,
                    cvd: this.cvd,
                    vault_id: this.vault_id,
                    save_to_vault: this.save_to_vault,
                    create_account: this.create_account,
                    password: this.password,
                    password_confirmation: this.password_confirmation,
                    optin_promotional_emails: this.optin_promotional_emails,
                    show_additional_inputs: this.showAdditionalInputs,
                };

                this.axios({
                    method: 'post',
                    timeout: 30000,    // 30 seconds timeout
                    url: process.env.VUE_APP_API_WHOLESALE + '/api/outlet/order',
                    data: data
                }).then(response => {
                    if (response.data.result === 'success') {
                        this.is_order_complete = true;

                        this.pushOrderTotal();

                        trackPurchase(this.productsInCart, this.grandTotal, this.tax, this.delivery, response.data.transaction_id);

                        this.$emit('order-complete', { email: this.shipping.email });
                        this.$store.dispatch('emptyCart');
                        this.$store.commit('resetPromo');

                        if (this.currentUser !== null) {
                            this.$store.dispatch('getCurrentUser');
                            this.$store.dispatch('fetchFavourites');
                        }

                        this.$nextTick(function() {
                            this.resetCheckout();
                            this.$router.push('/thankyou');
                        });
                    } else if (response.data.result === 'change') {
                        this.diffProducts(response.data.payload);

                        if (this.product_changes.length) {
                            this.show_modal = true;
                        }

                        this.$store.commit('updateProducts', response.data.payload);
                    }
                    else if (response.data.result === 'bad_fields') {
                        this.bad_fields = response.data.bad_fields
                        this.bad_field_modal = true;
                    }
                    else if (response.data.result === 'bad_slot') {
                        if (this.shipping.pickupLocation && this.shipping.pickupLocation.id === 0) {
                            this.availableProduceExpressDates = response.data.payload;
                        } else {
                            this.pickup_dates = response.data.payload;
                        }
                        this.bad_slot_modal = true;
                    }
                    else if (response.data.result === 'bad_delivery_slot') {
                        this.shippingTrigger = response.data.result;
                        this.bad_delivery_slot_modal = true;
                    }
                    else if (response.data.result === 'bad_address') {
                        this.bad_address_modal = true;
                        this.shippingTrigger = response.data.result;
                    }
                    else if (response.data.result === 'promo_fail') {
                        this.$store.commit('resetPromo');
                        this.promo_code_error = response.data.message;
                    }
                    else if (response.data.result === 'fundraising_fail') {
                        this.applied_fundraising_code = '';
                        this.fundraising_code_error = response.data.message;
                        window.scroll(0, 0); // Bring fundraising error into view
                    }
                    else if (response.data.result === 'bad_cart') {
                        let invalidIds = response.data.ids;

                        this.bad_cart_modal = true;
                        this.$store.commit('updateProducts', response.data.payload);

                        if (invalidIds.length) {
                            invalidIds.forEach(id => {
                                this.$store.dispatch('removeItemFromCart', id);
                            });
                        }
                    }
                    else if (response.data.result === 'bad_basket') {
                        this.bad_basket_modal = true;
                        this.$store.commit('updateProducts', response.data.payload);
                        window.scrollTo(0, 0);
                    }
                    else if (response.data.result === 'adjust_for_combos') {
                        this.adjustProductsForCombos(response.data.order_adjusted_for_combos);

                        if (this.product_combo_changes.length) {
                            this.adjust_for_combos_modal = true;
                        }

                        this.$store.commit('updateProducts', response.data.payload);
                    }
                    else if (response.data.result === 'fail') {
                        this.card_error = response.data.message;
                    }
                    else {
                        this.axios.get(process.env.VUE_APP_API_WHOLESALE + '/api/report_error', {
                            params: {
                                error: "Intent Failure: " + response.data.result,
                                name: this.shipping_first_name + " " + this.shipping_last_name,
                                phone: this.shipping_phone,
                                email: this.shipping_email,
                            }
                        });

                        this.problem_modal = true;
                    }
                })
                .catch(error => {
                    if (error?.response?.status === 422) {
                        this.form_errors = error.response.data.errors;

                        // errors for products field means we somehow submitted without products. Prompt user to try again.
                        if (this.form_errors.products?.length) {
                            this.problem_modal = true;
                        }
                    } else {
                        var error_message = '';

                        if (error.code !== undefined) error_message = error.code; // Timeout
                        if (error.response !== undefined) error_message = error.response.status; // 400/500 series

                        this.axios.get(process.env.VUE_APP_API_WHOLESALE + '/api/report_error', {
                            params: {
                                error: error_message,
                                name: this.shipping_first_name + " " + this.shipping_last_name,
                                phone: this.shipping_phone,
                                email: this.shipping_email,
                            }
                        });

                        this.problem_modal = true;
                    }
                })
                .finally(() => {
                    this.is_order_processing = false;
                });
            },

            resetCheckout () {
                this.touched.shipping.full_address = false;

                for (const property in initialData) {
                    if (this.currentUser && property === 'shipping') {
                        this.shipping.oldEmail = this.currentUser.email;

                        this[property] = {
                            ...initialData.shipping,
                            'first_name': this.currentUser.shipping_first_name ?? '',
                            'last_name': this.currentUser.shipping_last_name ?? '',
                            'phone': this.currentUser.shipping_phone ?? '',
                            'email': this.currentUser.email ?? '',
                            'address_1': this.currentUser.shipping_address_1 ?? '',
                            'address_2': this.currentUser.shipping_address_2 ?? '',
                            'city': this.currentUser.shipping_city ?? '',
                            'postal': this.currentUser.shipping_postal ?? '',
                            'province': this.currentUser.shipping_province ?? '',
                            'lat': this.currentUser.lat ?? '',
                            'lng': this.currentUser.lng ?? '',
                        };

                        this[property].full_address = this[property].address_1 + ' ' +
                            this[property].city + ', ' + this[property].province;
                    } else if (typeof this[property] === 'object' && this[property] !== null) {
                        if (Array.isArray(this[property])) {
                            this[property] = [ ...initialData[property] ]
                        } else {
                            this[property] = { ...initialData[property] }
                        }
                    } else {
                        this[property] = initialData[property];
                    }
                }

                this.fetchPeDates();
            },

            resetBillingFields () {
                this.billing_same_as_shipping = true;
                this.first_name = initialData.first_name;
                this.last_name = initialData.last_name;
                this.phone = initialData.phone;
                this.address_1 = initialData.address_1;
                this.address_2 = initialData.address_2;
                this.city = initialData.city;
                this.province = initialData.province;
                this.postal = initialData.postal;
            },

            diffProducts(updatedProducts) {
                this.productsInCart.forEach(product => {
                    var updatedProduct = updatedProducts.find(u => product.id === u.id);

                    if (!updatedProduct || (updatedProduct.stock !== null && updatedProduct.available !== null && updatedProduct.available <= 0) || updatedProduct.active !== product.active) {
                        this.product_changes.push(`${product.title} is no longer available`);
                        
                        this.$store.dispatch('removeItemFromCart', product.id);
                    }
                    else {
                        if (updatedProduct.price !== product.price || updatedProduct.unit !== product.size) {
                            this.product_changes.push(`${product.title} have changed from <strong>$${product.price}\\${product.size}</strong> to <strong>$${updatedProduct.price}\\${updatedProduct.unit}</strong>`);
                        }

                        if (updatedProduct.stock !== null && updatedProduct.available !== null && updatedProduct.available < product.quantity) {
                            this.product_changes.push(`${product.title} available quantity has changed from <strong>${product.quantity}</strong> to <strong>${parseFloat(updatedProduct.available)}</strong>`);
                            
                            this.$store.dispatch('updateCartItem', {
                                id: product.id,
                                qty: updatedProduct.available,
                                stock: product.stock,
                                price: product.price,
                            });
                        }

                        if (updatedProduct.max_order_qty !== null && updatedProduct.max_order_qty < product.quantity) {
                            this.product_changes.push(`${product.title} maximum allowed order quantity has changed from <strong>${product.quantity}</strong> to <strong>${parseFloat(updatedProduct.max_order_qty)}</strong>`);

                            this.$store.dispatch('updateCartItem', {
                                id: product.id,
                                qty: updatedProduct.max_order_qty,
                                stock: product.stock,
                                price: product.price,
                            });
                        }
                    }
                });
            },

            adjustProductsForCombos(updatedProducts) {
                this.productsInCart.forEach(product => {
                    var updatedProduct = updatedProducts.find(u => product.id === u.id);

                    if (updatedProduct)
                    {
                        if (updatedProduct.quantity < product.quantity) {
                            this.product_combo_changes.push(`${product.title} available quantity has changed from <strong>${product.quantity}</strong> to <strong>${parseFloat(updatedProduct.quantity)}</strong>`);
                            
                            this.$store.dispatch('updateCartItem', {
                                id: product.id,
                                qty: updatedProduct.quantity,
                                stock: product.stock,
                                price: product.price,
                            });
                        }
                    }
                });
            },

            closeModal() {
                this.show_modal = false;
                document.getElementById('table-summary').scrollIntoView();
            },

            formatDate(input) {
                let date = new Date(input);
                let options = {weekday: 'long', month: 'short',  day: 'numeric', year: 'numeric', timeZone: 'UTC'};

                return date.toLocaleDateString('en-US', options);
            },

            fetchPeDates () {
                this.axios.get(process.env.VUE_APP_API_WHOLESALE + '/api/pe-available-dates').then(response => {
                    this.availableProduceExpressDates = response.data;
                });
            },

            handleNavigateToCart () {
                this.show_modal = false;
                this.bad_cart_modal = false;
                this.bad_basket_modal = false;
                this.adjust_for_combos_modal = false;
                this.show_cart_has_updated_modal = false;
                this.$router.push('/cart');
            },

            handleInvalidModalClose () {
                this.invalid_location_products.forEach(p => {
                    this.$store.dispatch('removeItemFromCart', p.id);
                });

                this.invalid_location_products = [];
                this.show_invalid_location_modal = false;
            },

            pushOrderTotal () {
                const orderType = this.isDelivery ? 'delivery' : 'pickup';
                const orderTotal = this.grandTotal;

                window.dataLayer.push({
                    event : 'ga-event',
                    category : 'Purchase',
                    action : 'Submission',
                    label : orderType,
                    value : orderTotal,
                });
            },

            setAutocompleteAddress() {
                // google autocomplete
                const google = window.google = window.google ? window.google : {};
                const options = {
                    types: ['address'],
                    componentRestrictions: { country: ["ca"] },
                    fields: ["address_components", "geometry"],
                };

                this.autocompleteShippingAddress = new google.maps.places.Autocomplete(
                    (this.$refs.autocomplete_shipping),
                    options
                );

                this.autocompleteShippingAddress.addListener(
                    "place_changed",
                    () => this.getAutocompleteAddress('autocompleteShippingAddress')
                );
            },

            getAutocompleteAddress(autocomplete) {
                let place = this[autocomplete].getPlace();

                if (!place) {
                    return false;
                }

                let location = place.geometry.location.toJSON();
                let address = {
                    street_number: '',
                    street: '',
                    city: '',
                    locality: '',
                    neighborhood: '',
                    province: '',
                    postal: '',
                };

                for (const component of place.address_components) {
                    // @ts-ignore remove once typings fixed
                    const componentType = component.types[0];

                    switch (componentType) {
                        case "street_number":
                            address.street_number = component.long_name;
                            break;

                        case "route":
                            address.street = component.long_name;
                            break;

                        case "postal_code":
                            address.postal = component.long_name;
                            break;

                        case "locality":
                            address.city = component.long_name;
                            break;

                        case "sublocality_level_1":
                            address.locality = component.long_name;
                            break;

                        case "neighborhood":
                            address.neighborhood = component.long_name;
                            break;

                        case "administrative_area_level_1":
                            address.province = component.short_name;
                            break;
                    }
                }

                let city = address.city || address.neighborhood || address.locality;

                if (!city && place.address_components[2].types.includes('political')) {
                    address.city = place.address_components[2].long_name;
                }

                this.fillInShippingAddress(address, location);
            },

            fillInShippingAddress(address, location) {
                this.needConfirmAddress = false;
                this.showAdditionalInputs = false;

                if (location) {
                    this.shipping.lat = location.lat;
                    this.shipping.lng = location.lng;
                }

                this.shipping.address_1 = `${address.street_number} ${address.street}`;
                this.shipping.postal = address.postal;
                this.shipping.city = address.city || address.neighborhood || address.locality;
                this.shipping.province = address.province;
                this.shipping.full_address = `${this.shipping.address_1} ${this.shipping.city}, ${this.shipping.province}`;
                this.googleCity = this.shipping.city;
                this.googlePostalCode = this.shipping.postal;
                this.touched.autocomplete = true;
            },

            checkShippingAddress() {
                this.touched.shipping.full_address = true;

                setTimeout(() => {
                    if (this.touched.autocomplete && (!this.shipping.city || this.isShippingPostalInvalid)) {
                        this.showAdditionalInputs = true;
                    }
                }, 300);
            },

            clearShippingAddress() {
                this.showAdditionalInputs = false;
                this.shipping.lat = '';
                this.shipping.lng = '';
                this.shipping.address_1 = '';
                this.shipping.postal = '';
                this.shipping.city = '';
                this.shipping.province = '';
            },
        },

        watch: {
            currentUser () {
                this.resetCheckout();

                if (this.originalGuestCart.length) {
                    this.productsInCart.forEach(p1 => {
                        const match = this.originalGuestCart.find(p2 => p2.id === p1.id && p2.quantity === p1.quantity);

                        if (!match) {
                            this.show_cart_has_updated_modal = true;
                            return;
                        }
                    });
                }

                if (this.currentUser && this.isShippingAddressComplete) {
                    this.showShippingAddressForm = false;
                    this.showBillingAddressForm = false;
                }
            },

            'shipping.pickupLocation' (newValue) {
                this.productsInCart.forEach(p => {
                    if (p.store_excludes && p.store_excludes.includes(newValue.id)) {
                        this.invalid_location_products.push(p);
                    }
                });

                if (this.invalid_location_products.length) {
                    this.show_invalid_location_modal = true;
                }
            },

            billing_same_as_shipping (newValue) {
                if (!newValue) {
                    this.showBillingAddressForm = true;
                }
            },

            vault_id (newValue) {
                const vault = this.currentUser?.vaults.find(v => v.id === parseInt(newValue));

                if (!vault || vault.same_as_shipping) {
                    this.resetBillingFields();

                    return;
                }

                this.billing_same_as_shipping = vault.same_as_shipping;
                this.first_name = vault.first_name;
                this.last_name = vault.last_name;
                this.phone = vault.phone;
                this.address_1 = vault.address_1;
                this.address_2 = vault.address_2;
                this.city = vault.city;
                this.province = vault.province;
                this.postal = vault.postal;
            },
        },

        mounted () {
            // google autocomplete
            this.setAutocompleteAddress();

            this.promo_code = this.promo.code;

            this.resetCheckout();

            this.showBillingAddressForm = this.currentUser ? false : true;
        },

        activated () {
            if (!this.shipping.date) {
                this.fetchPeDates();
            }

            window.scrollTo(0, 0);

            this.showBillingAddressForm = this.currentUser ? false : true;
            this.showShippingAddressForm = this.currentUser ? false : true;

            if (this.currentUser && (this.isShippingPostalInvalid || !this.shipping.lat || !this.shipping.lng)) {
                this.showShippingAddressForm = true;
                this.needConfirmAddress = true;
                this.clearShippingAddress();

                return false;
            }
        },

        beforeRouteEnter (to, from, next) {
            next(vm => {
                trackBeginCheckout(vm.productsInCart, vm.grandTotal);

                if (vm.productsInCart.length === 0) {
                    vm.$router.replace({ name: 'cart' });
                } else if (!vm.currentUser) {
                    vm.originalGuestCart = JSON.parse(JSON.stringify(vm.productsInCart));
                }
            });
        },

        beforeRouteLeave (to, from, next) {
            this.originalGuestCart = [];
            next();
        }
    }
</script>

<style scoped>
    h3 {
        margin: 1rem 0;
    }

    .pricing h3 {
        margin-top: 0;
    }

    img {
        max-width: 100%;
        height: auto;
    }

    .invalid {
        border-color: #cc0000;
        box-shadow: none;
    }

    .basket-items {
        list-style-type: none;
        padding-left: 2px;
        font-size: 14px;
    }

    .cart-updates p {
        font-size: 1.125rem;
    }

    .gift-order label {
        font-size: 22px;
    }

    .login-reminder {
        padding: 0.5rem 1rem !important;
    }

    .login-reminder p {
        font-size: 16px;
        line-height: 1.3;
    }
</style>
