<template>
    <div class="add-to-cart">
        <!-- Limited by stock qty message  -->
        <div class="available-qty" v-if="maxQty === parseFloat(productStock)" v-show="showAvailableQty">
            Only {{ productStock * 1}} available
        </div>

        <!-- Limited by max qty message -->
        <div class="available-qty" v-else-if="maxQty === parseFloat(product.max_order_qty)" v-show="showAvailableQty">
            Maximum {{ product.max_order_qty * 1}} allowed
        </div>

        <!-- Limited by hard_max message -->
        <div class="available-qty" v-else v-show="showAvailableQty">
            Only {{ hard_max }} available
            <div v-if="product.id == 103"> We're happy people but {{ hard_max + 1 }} smiles is too much! :)</div>
        </div>

        <template v-if="isVisible">
            <button @click.stop="handleAdd" v-if="showAddToCartButton && product.quantity == 0" class="btn btn-primary btn-add mt-2" :class="btnSize">
                Add
            </button>

            <div class="input-group flex-nowrap" v-if="!showAddToCartButton || (showAddToCartButton && product.quantity > 0)">
                <div class="input-group-prepend">
                    <button class="btn btn-secondary btn-sm btn-minus" v-on:click.stop="decrement" :disabled="disableDecrement">-</button>
                </div>
                <input
                    type="number"
                    v-model.number="quantity"
                    :max="maxQty"
                    :disabled="dayOfWeek == 0"
                    @click.stop
                    min="1"
                    class="form-control form-control-sm qty"
                    style="background-color:white"
                />
                <div class="input-group-append">
                    <button class="btn btn-secondary btn-sm btn-plus" v-on:click.stop="increment">+</button>
                </div>
            </div>
        </template>

        <div class="sold-out" v-else-if="!hasStock">Sold out, coming soon!</div>
        <div class="sold-out" v-else>Unavailable at selected pickup location</div>
    </div>
</template>

<script>
    import { mapState, mapGetters, mapActions } from 'vuex'

    export default {
        props: ['product', 'showAddToCartButton', 'disableDecrement', 'btnSize'],

        data () {
            return {
                dayOfWeek: new Date().getDay(),
                showAvailableQty: false,
                hard_max: 999,
            }
        },

        computed: {
            ...mapState({
                shipping: state => state.products.shipping,
            }),

            ...mapGetters([
                'products',
            ]),

            quantity: {
                get () {
                    return this.product.quantity;
                },

                set (value) {
                    this.checkAvailableQty(value);

                    this.updateCartItem({
                        id: this.product.id,
                        qty: value,
                        stock: this.productStock,
                        price: this.product.price,
                    });
                }
            },

            subitemStockLimit() {
                let subitem_stock_limit = null;

                if (this.product.require_subitem_quantities) {
                    let limiting_stock = null;

                    this.product.subitems.forEach(subitem => {
                        if (subitem.qty > 0 && subitem.subitem_available !== null) {
                            let limited_stock = Math.floor(subitem.subitem_available / subitem.qty);

                            if (limiting_stock == null || limited_stock < limiting_stock) {
                                limiting_stock = limited_stock;
                            }
                        }
                    });

                    if (this.product.stock === null || limiting_stock < this.product.stock) {
                        subitem_stock_limit = limiting_stock;
                    }
                }

                return subitem_stock_limit;
            },

            productStock() {
                let product_stock = this.product.available;

                if (this.product.allow_oversell) {
                    return this.hard_max;
                }

                if (this.subitemStockLimit !== null) {
                    product_stock = this.subitemStockLimit;
                }

                return product_stock;
            },

            maxQty() {
                let stock = this.productStock || this.hard_max;
                let max = this.product.max_order_qty || this.hard_max;

                stock = parseFloat(stock);
                max = parseFloat(max);

                return stock < max ? stock : max;
            },

            hasStock () {
                return this.productStock === null || this.productStock > 0;
            },

            isLocationValid () {
                if (this.product.store_excludes && this.product.store_excludes.includes(this.shipping.pickupLocation)) {
                    return false;
                }

                return true;
            },

            isVisible () {
                return this.hasStock && this.isLocationValid && this.product.active;
            }
        },

        methods: {
            ...mapActions(['updateCartItem']),

            increment() {
                if (this.dayOfWeek != 0) // Disable on Sunday
                {
                    let qty = this.quantity + 1;

                    this.checkAvailableQty(qty);

                    this.updateCartItem({
                        id: this.product.id,
                        qty: qty,
                        stock: this.productStock,
                        price: this.product.price,
                    });
                }
            },

            decrement() {
                if (this.dayOfWeek != 0) // Disable on Sunday
                {
                    let qty = this.quantity - 1;

                    this.checkAvailableQty(qty);

                    this.updateCartItem({
                        id: this.product.id,
                        qty: qty,
                        stock: this.productStock,
                        price: this.product.price,
                    });
                }
            },

            handleAdd () {
                if (this.dayOfWeek != 0) // Disable on Sunday
                {
                    this.updateCartItem({
                        id: this.product.id,
                        qty: 1,
                        stock: this.productStock,
                        price: this.product.price,
                    });
                }
            },

            checkAvailableQty (value) {
                let stock = this.productStock;
                let max = this.product.max_order_qty;
                let hardMax = this.hard_max;

                if ((max !== null && value > max) ||
                    (stock !== null && value > stock) ||
                    (stock === null && value > hardMax)
                ){
                   this.showAvailableQty = true;

                    setTimeout(() => {
                        this.showAvailableQty = false
                    }, 1000);
                }
            }
        },
    }
</script>

<style scoped>
    .add-to-cart {
        display: inline-block;
        position: relative;
    }

    @media (min-width: 768px) {
        .add-to-cart {
            margin: 0 auto;
        }
    }

    .btn-add {
        background: #85C71E;
        border-color: #85C71E;
        padding-left: 3.5rem;
        padding-right: 3.5rem;
    }

    .btn-add:hover {
        background: #6CAE05;
        border-color: #6CAE05;
    }

    .input-group {
        max-width: 150px;
        margin-top: 0.5rem;
    }

    .input-group .qty {
        border-radius: 0 !important;
        font-size: 18px;
        height: 40px;
        padding: 0 6px;
        text-align: center;
        min-width: 60px;
    }

    .btn-minus,
    .btn-plus {
        font-size: 1.5rem;
        line-height: 1;
    }

    .available-qty {
        position: absolute;
        bottom: 60px;
        left: calc(50% - 58px);
        background: rgba(0, 0, 0, 0.85);
        border-radius: 8px;
        color: #fff;
        padding: 3px 6px;
        font-size: 14px;
        z-index: 99;
        text-align: center;
    }
</style>
